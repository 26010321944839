/*eslint-disable */

export function getDetails (state) {
  return {
    id: state.id,
    businessName: state.businessName,
    name: state.name,
    isCCNSW: state.isCCNSW,
    acn: state.acn,
    abn: state.abn,
    about: state.about,
    phone: state.phone,
    fax: state.fax,
    email: state.email,
    emailOrders: state.emailOrders,
    emailCredits: state.emailCredits,
    brands: state.brands,
    supplierType: state.supplierType,
    supplierTypes: state.supplierTypes,
    supplierVehicleCategory: state.supplierVehicleCategory,
    address: {
      address: state.address.address,
      unitLevelLot: state.address.unitLevelLot,
      street: state.address.street,
      suburb: state.address.suburb,
      postcode: state.address.postcode,
      country: state.address.country,
      state: state.address.state
    },
    displayId: state.displayId,
    settings: state.settings,
    logoImgPath: state.logoImgPath,
    primaryBusinessAddress: state.primaryBusinessAddress,
  }
}

export function getTimezone (state) {
  return state.timezone
}

export function isLoadedCompanyInfo (state) {
  return state.isLoadedInfo
}

export function getLocations (state) {
  return state.locations
}

export function getContacts (state) {
  return state.contacts
}

export function isOEM (state) {
  return state.supplierType === 'OEM-GEN' || state.supplierType === 'OEM'
}

export function isOEMGEN (state) {
  return state.supplierType === 'OEM-GEN' || state.supplierType === 'OEM'
}

export function isOEMPAR (state) {
  return state.supplierType === 'OEM-PAR'
}

export function isUSED (state) {
  return state.supplierType?.includes('USED')
}

export function isRECO (state) {
  return state.supplierType?.includes('RECO')
}

export function isOEMALT (state) {
  return state.supplierType?.includes('OEM-ALT')
}

export function isAFTM (state) {
  return state.supplierType?.includes('AFTM')
}

export function isHasOEMGEN (state) {
  return state?.supplierTypes?.includes('OEM-GEN')
}

export function isHasOEMPAR (state) {
  return state?.supplierTypes?.includes('OEM-PAR')
}

export function isHasUSED (state) {
  return state?.supplierTypes?.includes('USED')
}

export function isHasRECO (state) {
  return state?.supplierTypes?.includes('RECO')
}

export function isHasOEMALT (state) {
  return state?.supplierTypes?.includes('OEM-ALT')
}

export function isHasCERTAFTM (state) {
  return state?.supplierTypes?.includes('CERT-AFTM')
}

export function isHasAFTM (state) {
  return state?.supplierTypes?.includes('AFTM')
}

export function isEnableSettingsRfqImportPdfQuote (state) {
  return (state.settings?.rfq?.importQuoteOptions?.importPdfQuote === true)
}

export function isEnableSettingsRfqImportPdfShowModal (state) {
  return (state.settings?.rfq?.importQuoteOptions?.importPdfQuote === true && state.settings?.rfq?.importQuoteOptions?.importCheck === true)
}

export function isEnableSettingsRfqImporCsvQuote (state) {
  return (state.settings?.rfq?.importQuoteOptions?.importCsvQuote === true)
}

export function isEnableSettingsRfqImportCsvShowModal (state) {
  return (state.settings?.rfq?.importQuoteOptions?.importPdfQuote === true && state.settings?.rfq?.importQuoteOptions?.importCsvQuote === true && state.settings?.rfq?.importQuoteOptions?.importCheckCsv === true)
}

export function isBranding (state) {
  return ((state.isCCNSW && state.isCCNSW == true) || state.brandingEnabled === true)
}

export function getBrandingClassName(state){

 if (state.isCCNSW && state.isCCNSW == true) { return 'branding-5'}
  return 'branding-5'
  // return 'branding-'+state.id
}



