<script>
/*eslint-disable */

import LoginDefault from '@/views/auth/default/login.vue'
import LoginCcnsw from '@/views/auth/ccnsw/login.vue'

export default {
  components: { LoginDefault, LoginCcnsw },
  data () {
    return {
      host: '',
    }
  },
  methods: {},
  mounted () {
    this.host = window.location.hostname
  },
  computed: {
    isCCNSW () {
      return (this.host == 'ccnsw.stg.partssearch.com.au')
    },
    isDefault () {
      return !this.isCCNSW
    }
  }
}
</script>

<template>
  <login-ccnsw v-if="isCCNSW"></login-ccnsw>
  <login-default v-else></login-default>
</template>

