<template>
    <div class="row integrations-page">
        <div class="col-lg-6">
            <b-table
                responsive
                hover
                :items="apiKeys"
                :fields="fields"
                @row-clicked="onRowClick"
                :tbody-tr-class="rowClass"
            >
                <template v-slot:cell(status)="data">
                    <template v-if="data.item.status">
                        <span class="active"> Active </span>
                    </template>
                    <template v-else>
                        <span class="not-active">Not Active </span>
                    </template>
                </template>
                <template v-slot:cell(actions)="data">
                    <button
                        class="btn-edit"
                        @click="onEditClick(data.item)"
                        v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Edit Key', placement:'leftbottom'}"
                    ></button>
                    <button
                        class="btn-trush"
                        @click="onRemoveClick(data.item)"
                        v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Delete Key', placement:'leftbottom'}"
                    ></button>
                </template>
            </b-table>
        </div>

        <div class="col-lg-6">
            <integrations-create-form :new-generated-key="newKey" @on-create="onCreate($event)" @on-cancel="onCreateCancelClick" v-if="isNew"></integrations-create-form>
            <div v-else class="detail-section">
                <div class="section-header">Api Key</div>

                <div class="detail-body row">

                    <div class="col-md-12 user-detail-section">
                        <div class="form-group row title-edit-form">
                            Reference this API key in your system or with your system vendor to enable submission of quotes to PartSearch
                        </div>

                        <div class="form-group row">
                            <label for="username" class="col-lg-3 form-label">Name</label>
                            <div class="col-lg-9">
                                <label
                                    v-if="!isEdit"
                                    class="col-form-label form-text"
                                >{{ selectedItem.name }}</label>
                                <input
                                    v-else
                                    type="text"
                                    class="form-control"
                                    v-model="selectedItem.name"
                                    id="username"
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-lg-3 pl-0 form-label">System Name</label>
                            <div v-if="selectedItem.system_name" class="col-lg-9">
                                <label class="col-form-label form-text">{{ selectedItem.system_name }}</label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-lg-3 form-label">Api Key</label>
                            <div v-if="selectedItem.hash" class="col-lg-9">
                                <label
                                    class="col-form-label form-text">{{
                                        selectedItem.hash
                                    }}</label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-lg-3 form-label" for="status">Status</label>
                            <div class="col-lg-9">
                                <div v-if="!isEdit" class="d-flex">
                                    <label v-if="selectedItem.status" class="col-form-label form-text">
                                        <div class="active-icon"></div>
                                        <span class="active-user-text">Active</span>
                                    </label>
                                    <label v-else class="col-form-label form-text">
                                        <div class="active-icon inactive"></div>
                                        <span class="active-user-text">Not Active</span>
                                    </label>
                                </div>
                                <div v-else class="d-flex">
                                    <b-form-checkbox
                                        v-model="selectedItem.status"
                                        name="check-button"
                                        switch
                                        class="switch-checkbox"
                                    ></b-form-checkbox>
                                    <div class="status-text">
                                        <span v-if="selectedItem.status" class="form-text">Active</span>
                                        <span v-else class="form-text">Not Active</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row action-btns">
                            <template v-if="isEdit">
                                <button class="update-btn" @click="onUpdateClick()">Update</button>
                                <button class="edit-btn" @click="onCancelEditClick()">Cancel</button>
                            </template>
                            <template v-else>
                                <button class="edit-btn" @click="onEditClick(selectedItem)">Edit</button>
                                <button class="delete-btn" @click="onRemoveClick(selectedItem)">Delete</button>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Axios from 'axios';
import IntegrationsCreateForm from "./integrations/create-form";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "SettingsIntegrations",
    components:{
      IntegrationsCreateForm
    },
    props: {
      propsKeys: {
        type: Array,
        default: () => {
          return []
        }
      },
      companyId: {
        type: [Number, String],
        default: () => {
          return null
        }
      },
    },
    data(){
        return {
            newKey: {},
            systemNames: [
                'systemName1',
                'systemName2',
                'systemName3',
                'systemName4'
            ],
            fields: [
                {
                    label: "Name",
                    key: "name",
                    tdClass: "name clickable td-integrations",
                    thClass: "table-header"
                },
                {
                    label: "System Name",
                    key: "system_name",
                    tdClass: "system-name clickable td-integrations",
                    thClass: "table-header"
                },
                {
                    label: "Status",
                    key: "status",
                    tdClass: "status clickable td-integrations",
                    thClass: "table-header"
                },
                {
                    label: "",
                    key: "actions",
                    tdClass: "actions clickable text-right td-integrations",
                    thClass: "table-header"
                }
            ],
            selectedItem: {
                hash: 0
            },
            isEdit: false,
            isNew: false
        }
    },
  computed: {
      ...mapGetters({
        getterApiKeys: 'currentUser/apiKeys',
        isMultipleLocations: 'settings/isMultipleLocations'
      }),
      apiKeys() {
        return this.isMultipleLocations ? _.cloneDeep(this.propsKeys) : _.cloneDeep(this.getterApiKeys)
      },
  },
  mounted() {
        this.selectedItem = Object.assign({}, this.apiKeys[0]);
        this.$nextTick(() => {
            this.loadApiKeys()
        })
    },
    methods:{
        ...mapActions({
          loadApiKeys: 'currentUser/loadApiKeys',
        }),
        onRowClick(item){
            this.selectedItem = Object.assign({}, item);
            console.log('onRowClick', item);
        },
        onEditClick(item){
            console.log('onEditClick', item);
            this.selectedItem = Object.assign({}, item);
            this.isEdit = true;
        },
        onRemoveClick(item){
            let fd = new FormData();
            fd.append('hash', item.hash);
            Axios.post(`/ir/api-keys/delete`, fd)
                .then(response => {
                  console.log(response)
                  let data = response.data;
                  if(data.result){
                    this.loadApiKeys();

                    if(this.apiKeys.length){
                      this.selectedItem = this.apiKeys[0];
                    }

                  } else {
                    this.$toast.error("Cannot delete key");
                  }
                })
                .catch(error => {
                  this.$toast.error("Cannot delete key");
                  console.log(error);
                });
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return;
            if (item.hash === this.selectedItem.hash) return 'table-tr-selected';
        },
        onUpdateClick(){
            console.log(this.selectedItem);
            let fd = new FormData();
            fd.append('hash', this.selectedItem.hash);
            fd.append('name', this.selectedItem.name);
            fd.append('status', this.selectedItem.status * 1);
            Axios.post(`/ir/api-keys/update`, fd)
                .then(response => {
                  this.isEdit = false;
                  console.log(response)
                  let data = response.data;
                  if(data.result){
                    this.loadApiKeys();
                  } else {
                    this.$toast.error("Cannot update key");
                  }
                })
                .catch(error => {
                  this.$toast.error("Cannot update key");
                  console.log(error);
                });
        },
        onCancelEditClick(){
            this.isEdit = false;
        },
        onCreateClick(){
            Axios.get(`/ir/api-keys/new`)
                .then(response => {
                    let data = response.data;
                    if(data.result){
                      this.newKey = data.key;
                      this.isNew = true;
                    }
                })
                .catch(error => {
                  toastr.error("Cannot create key");
                  console.log(error);
                });
        },
        onCreate(nk){
          let fd = new FormData();
          fd.append('hash', nk.hash);
          fd.append('name', nk.name);
          fd.append('system_name', nk.system_name);
          Axios.post(`/ir/api-keys/update`, fd)
              .then(response => {
                this.isNew = false;
                console.log(response)
                let data = response.data;
                if(data.result){
                  this.loadApiKeys();
                } else {
                  this.$toast.error("Cannot save key");
                }
              })
              .catch(error => {
                this.$toast.error("Cannot save key");
                console.log(error);
              });
        },
        onCreateCancelClick() {
          let fd = new FormData();
          fd.append('hash', this.newKey.hash);
          Axios.post(`/ir/api-keys/delete`, fd)
              .then(response => {
                console.log(response)
                let data = response.data;
                if(data.result){
                  this.loadApiKeys();

                  if(this.apiKeys.length){
                    this.selectedItem = this.apiKeys[0];
                  }

                } else {
                  this.$toast.error("Cannot delete key");
                }
                this.isNew = false;
              })
              .catch(error => {
                this.$toast.error("Cannot delete key");
                console.log(error);
              });
        },
    }
}
</script>

<style scoped>
    .integrations-page >>> .td-integrations {
        height: 37px !important;
        padding: 0 15px;
        font-size: 12px;
    }

    .integrations-page >>> .title-edit-form {
        font-size: 12px;
    }

    .integrations-page >>> .status {
        font-weight: normal !important;
    }

    .integrations-page >>> .active {
        color: #2DCA73;
    }

    .integrations-page >>> .not-active{
        color: #79B1BB;
    }

    .integrations-page >>> .table-tr-selected{
        background-color: rgba(41, 187, 193, 0.1);
        color: #79B1BB;
    }

    .integrations-page >>> .table-tr-selected .active{
        color: #79B1BB !important;
    }

    .integrations-page >>> .delete-btn{
        box-sizing: border-box;
        height: 31px;
        width: 101px;
        border: 1px solid #FF5E5E;
        color: #FF5E5E;
        border-radius: 3px;
    }

    .integrations-page >>> .form-label{
        padding-left: 0 !important;
        padding-bottom: 3px;
        padding-top: 6px;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
    }

</style>
