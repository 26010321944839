<script>
/* eslint-disable */
import fullscreen from 'vue-fullscreen'
import Vue from 'vue'
import { isMobileOnly } from 'mobile-device-detect'
import { mapGetters } from 'vuex'
import { vAvatar } from '@amaury-tobias/v-avatar'
import { appConfig } from '../../config'
    import Multiselect from 'vue-multiselect'
    import CompanyToggle from '../utility/multiple-brand-toggle.vue'
    import _ from "lodash";

Vue.use(fullscreen)

    export default {
        name: 'site-header',
        components: {
            vAvatar,
          multiselect: Multiselect,
          CompanyToggle,
        },
        data() {
            return {
                fullscreen: false,
                isCompactSideBar: false,
                resizer: null,
                showCompactSideBarForMobileVertical: false,
                isImageError: false,
            };
        },
        methods: {
            getBusinessHash() {
              if (this.isMultipleLocations) {
                // let index = _.findIndex(this.getterSettings.multipleBrands, (b) => {
                //   return Number(b.id) === Number(this.initialCompanyInfo.id)
                // })
                // if (this.getterSettings?.multipleBrands?.[index]?.id === this.initialCompanyInfo.id) {
                //   return '#businessProfile'
                // } else if (this.getterSettings?.multipleBrands?.[index]) {
                //   return '#businessProfile-' + this.initialCompanyInfo.id
                // }
                return '#businessProfile-' + this.initialCompanyInfo.id
              }
              return '#businessProfile'
            },
            onImageError() {
              this.isImageError = true
            },
            handleResize() {
                if (this.resizer) {
                    clearTimeout(this.resizer);
                }
                let param = 425;
                this.resizer = setTimeout(() => {
                    let body = document.querySelector('body');
                    this.showCompactSideBarForMobileVertical = isMobileOnly && ((body.clientWidth > param));
                    if (this.showCompactSideBarForMobileVertical) {
                        body.classList.add('compact-sidebar');
                    } else if (!isMobileOnly && body.clientWidth <= 768){
                        body.classList.add('compact-sidebar');
                    }
                    else {
                        body.classList.remove('compact-sidebar');
                    }
                }, 100);
            },
            toggleSideBarFirst: function () {
                let body = document.querySelector('body');
                body.classList.toggle('site-sidebar-opened');
                return false;
            },
            toggleSideBarSecond: function () {
                let body = document.querySelector('body');
                body.classList.toggle('compact-sidebar');
                if (body.classList.contains('compact-sidebar')) {
                  this.isCompactSideBar = true;
                } else {
                  this.isCompactSideBar = false;
                }
                return false;
            },
            logout: function () {
                NProgress.start();
                let wrapper = document.getElementById('app-wrapper');
                wrapper.style.opacity = 0;
                setTimeout(() => {
                  this.$store.dispatch('auth/logout')
                }, 500)
            },
            toggleFullscreen() {
                let body = document.querySelector('body');
                this.$fullscreen.toggle(body, {
                    wrap: false,
                    callback: this.fullscreenChange
                });
                if (this.isCompactSideBar) {
                  this.isCompactSideBar = false;
                }
            },
            fullscreenChange(fullscreen) {
                this.fullscreen = fullscreen;
            }
        },
        computed: {
            ...mapGetters({
                  currentUserAvatar: 'currentUser/getCurrentUserAvatar',
                  currentUser: 'currentUser/getCurrentUserInfo',
                  initialCompanyInfo: 'currentCompany/getDetails',
                  isAdministrator: 'currentUser/isAdministrator',
                  isPartsManager: 'currentUser/isPartsManager',
                  isUserTypeSupplier: 'currentUser/isUserTypeSupplier',
                  isMultipleLocations: 'settings/isMultipleLocations',
                  getterSettings: 'settings/getSettings',
                  getterMultipleBrands: 'currentUser/getMultipleBrands',
              isBranding: 'currentCompany/isBranding',
              brandingClass: 'currentCompany/getBrandingClassName',
            }),
          avatarColor () {
            if (this.isBranding) {
              return this.brandingClass
            }
            return 'theme-color'
          },
            isShowBusinessTab () {
              return this.isAdministrator || this.isPartsManager
            },

            count: function () {
                return this.$store.state.count;
            },
            userInfo: function () {
                return this.$store.state.currentUser;
            },
        },
        mounted() {
            window.addEventListener('resize', this.handleResize);
            this.$nextTick(function () {
                this.handleResize();
            });
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleResize);
        },
    };
</script>

<template>
    <div>

        <!-- Preloader -->
        <div class="preloader" style="display: none"></div>
        <!-- Header -->
        <div class="site-header">
            <b-navbar class="mobile-header mobile-show">
                <b-navbar-nav class="center-middle">
                    <a @click="'/'" class="navbar-brand-logo-svg-header"></a>
                    <div @click="toggleSideBarFirst"
                         class="toggle-button light sidebar-toggle-first float-xs-left hidden-md-up">
                        <span class="hamburger"></span>
                    </div>
                </b-navbar-nav>
                <ul class="navbar-nav ml-auto">
                    <b-nav-item-dropdown right>
                        <template v-slot:button-content>
                                <span class="avatar">
                                   <img class="" :src="currentUserAvatar" @error="onImageError" style="display: none">
                                    <v-avatar
                                            :username="userInfo.fullName"
                                            :size="40"
                                            :src="!isImageError ? currentUserAvatar : ''"
                                            color="#fff"
                                            rounded
                                            :background-color="`var(--${avatarColor})`"
                                    />
                                </span>
                        </template>

                        <router-link :to="{name: 'Settings', hash: '#settings-account'}" class="dropdown-item">
                            <i class="ti-user mr-0-5"></i> My Account
                        </router-link>
                        <router-link v-if="isShowBusinessTab"
                                     :to="{name: 'Settings', hash: getBusinessHash()}" class="dropdown-item">
                            <i class="ti-briefcase mr-0-5"></i> Business
                        </router-link>
                        <div class="dropdown-divider"></div>
                        <a href="#" class="dropdown-item  clickable" @click.prevent="logout"><i
                                class="ti-power-off mr-0-5"></i> Sign out</a>
                    </b-nav-item-dropdown>

                </ul>
            </b-navbar>
            <b-navbar class="mobile-hide">
                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav>
                        <div @click="toggleSideBarSecond"
                             class="nav-link toggle-button light sidebar-toggle-first float-xs-left hidden-md-up">
                            <i v-if="isCompactSideBar" class="compact-sidebar-icon bx bx-chevrons-right"></i>
                            <i v-else class="compact-sidebar-icon header-icon bx bx-chevrons-left"></i>
                        </div>
                        <div @click="toggleFullscreen" class="nav-link toggle-fullscreen">
                            <i v-if="fullscreen" class="fullscreen-icon exit-fullscreen-icon bx bx-exit-fullscreen"></i>
                            <i v-else class="fullscreen-icon bx bx-fullscreen"></i>
                        </div>
                    </b-navbar-nav>
                    <div class="business-name-text">
                      <template v-if="isUserTypeSupplier">
                        <template v-if="isMultipleLocations && getterMultipleBrands && getterMultipleBrands.length">
                          <company-toggle :company="initialCompanyInfo.businessName">
                          </company-toggle>
                        </template>
                        <template v-else>
                          {{ initialCompanyInfo.businessName }}
                        </template>
                      </template>
                      <template v-else>
                         {{ initialCompanyInfo.businessName }}
                      </template>
                    </div>

                    <!-- Right aligned nav items -->
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item d-block">
                            <span class="full-name">{{ userInfo.firstName }} {{ userInfo.lastName }}</span>
                        </li>
                        <b-nav-item-dropdown right>
                            <template v-slot:button-content>
                                <span class="avatar">
                                    <img class="" :src="currentUserAvatar" @error="onImageError" style="display: none">
                                    <v-avatar
                                            :username="userInfo.fullName"
                                            :size="40"
                                            :src="!isImageError ? currentUserAvatar : ''"
                                            color="#fff"
                                            rounded
                                            :background-color="`var(--${avatarColor})`"
                                    />
                                </span>
                            </template>

                            <router-link :to="{name: 'Settings', hash: '#settings-account'}" class="dropdown-item">
                                <i class="ti-user mr-0-5"></i> My Account
                            </router-link>
                            <router-link v-if="isShowBusinessTab"
                                         :to="{name: 'Settings', hash: getBusinessHash()}" class="dropdown-item">
                                <i class="ti-briefcase mr-0-5"></i> Business
                            </router-link>
                            <div class="dropdown-divider"></div>
                            <a href="#" class="dropdown-item  clickable" @click.prevent="logout"><i
                                    class="ti-power-off mr-0-5"></i> Sign out</a>
                        </b-nav-item-dropdown>
                    </ul>
                </b-collapse>
            </b-navbar>
            <nav class="navbar navbar-light" style="display:none;">
                <div class="navbar-left center-middle">
                    <a @click="'/'" class="navbar-brand-logo-svg-header"></a>
                    <div @click="toggleSideBarFirst"
                         class="toggle-button light sidebar-toggle-first float-xs-left hidden-md-up">
                        <span class="hamburger"></span>
                    </div>
                </div>
                <div class="navbar-right navbar-toggleable-sm collapse show" id="collapse-1">
                    <div @click="toggleSideBarSecond" class="toggle-button light sidebar-toggle-second hidden-sm-down">
                        <span class="hamburger"></span>
                    </div>
                    <ul class="nav navbar-nav1 float-md-right">
                        <li class="nav-item hidden-sm-down">
                            <span class="nav-link active">{{ userInfo.firstName }} {{ userInfo.lastName }}</span>
                        </li>
                        <li class="nav-item dropdown hidden-sm-down">
                            <a href="#" data-toggle="dropdown" aria-expanded="false">
                                <span class="avatar">
                                   <img class="" :src="currentUserAvatar" @error="onImageError" style="display: none">
                                    <v-avatar
                                            :username="userInfo.fullName"
                                            :size="40"
                                            :src="!isImageError ? currentUserAvatar : ''"
                                            color="#fff"
                                            rounded
                                            :background-color="`var(--${avatarColor})`"
                                    />
                                </span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right animated fadeInUp">
                                <router-link :to="{name: 'Settings', hash: '#settings-account'}" class="dropdown-item">
                                    <i class="ti-user mr-0-5"></i> My Account
                                </router-link>
                                <router-link v-if="isShowBusinessTab"
                                             :to="{name: 'Settings', hash: getBusinessHash()}" class="dropdown-item">
                                    <i class="ti-briefcase mr-0-5"></i> Business
                                </router-link>

                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#"><i class="ti-help mr-0-5"></i> Help</a>
                                <a href="#" class="dropdown-item clickable" @click.prevent="logout"><i
                                        class="ti-power-off mr-0-5"></i> Sign out</a>
                            </div>
                        </li>
                    </ul>
                    <ul class="nav navbar-nav">
                        <li class="nav-item hidden-sm-down">
                            <a class="nav-link toggle-fullscreen" href="#">
                                <i class="ti-fullscreen"></i>
                            </a>
                        </li>

                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<style scoped>
.business-name-text {
    padding-top: 8px;
    margin-left: 20px;
    color: #1B1E38;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
}
</style>

<style>
.business-name-text .multiselect .multiselect__single {
  font-size: 16px !important;
}

.business-name-text .multiselect .multiselect__tags {
  min-width: 252px !important;
  width: auto;
}

.business-name-text .multiselect .multiselect__option {
  white-space: normal !important;
  height: auto;
  min-height: 30px;
}
</style>
